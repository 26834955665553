import React from 'react';
import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import StyledHeading from '../components/core/typography/Heading';
import styled from 'styled-components';
import { StyledGeneratedHtmlContainer } from './privacy-e-cookie-policy';

export const Heading = styled(StyledHeading)`
  margin-top: 2rem;
`;
const TermsAndConditions = () => {
  return (
    <div tw="py-16">
      <SEO title="Termini e condizioni" url="/termini-e-condizioni" />
      <Container tw="mb-10">
        <Heading variant="h1" tw="mb-5 md:mb-0">
          Termini e condizioni generali
        </Heading>
        <Heading variant="h4" tw="mb-10">
          relativa al sito internet www.hellougo.com
        </Heading>
      </Container>
      <section tw="mb-16">
        <Container tw="text-paragraph">
          <StyledGeneratedHtmlContainer>
            <p>Condizioni di utilizzo Ugo</p>
            <p>
              Ugo Pnp S.r.l., con sede legale in 20124 - Milano, Via Panfilo
              Castaldi 29 (di seguito,{' '}
              <strong>
                <em>Società o UGO PNP</em>
              </strong>
              ), ha realizzato la piattaforma denominata “UGO” (nel seguito, la{' '}
              <strong>
                <em>Piattaforma</em>
              </strong>
              ) raggiungibile presso il nome a dominio https://hellougo.com/ ed
              eventuali sottodomini e di cui ha la proprietà esclusiva.{' '}
            </p>
            <p>
              <strong>
                Le presenti condizioni di utilizzo (di seguito, <em>CU </em>o{' '}
                <em>Condizioni di Utilizzo</em>) disciplinano i rapporti
                relativi ai servizi offerti da UGO PNP a favore degli utenti,
                come sotto definiti, e devono essere considerate vincolanti
                congiuntamente all'Informativa sulla Privacy e l’informativa sui
                cookie.
              </strong>
            </p>
            <p>
              UGO PNP fornisce un insieme di servizi, tra cui uno spazio online
              a disposizione degli Utenti, come <em>infra </em>
              definiti, che permette di mettere in comunicazione gli Utenti con
              soggetti privati, opportunamente selezionati e formati,
              disponibili alla prestazione, nei confronti degli Utenti, di
              servizi di affiancamento e/o accompagnamento di persone fragili o
              con ridotta mobilità, al fine di aiutarle nello svolgimento di
              attività quotidiane (nel seguito, il{' '}
              <strong>
                <em>Servizio</em>
              </strong>
              ).{' '}
            </p>
            <p>
              <strong>
                Utilizzando il Servizio, gli utenti della Piattaforma accettano
                le Condizioni di Utilizzo.
              </strong>{' '}
              Il Servizio reso da UGO PNP e l’utilizzo della Piattaforma, come{' '}
              <em>infra </em>definiti, sono soggetti all’applicazione delle CU e
              di ogni altro regolamento o policy pubblicata da UGO PNP sulla
              Piattaforma. In caso di disaccordo rispetto a qualsiasi termine o
              condizione o successive modifiche delle stesse, ovvero nel caso di
              insoddisfazione per i Servizi offerti da UGO PNP, l’utente avrà
              facoltà di interrompere immediatamente l'uso della Piattaforma.
              UGO PNP si impegna a far rispettare rigorosamente le Condizioni di
              Utilizzo attraverso la moderazione della comunità ed il
              contenzioso nelle opportune sedi giudiziarie.
            </p>
            <p>
              <strong>
                Le presenti Condizioni di Utilizzo disciplinano il rapporto tra
                UGO PNP e gli UGO nonché le regole di utilizzo della Piattaforma
                e di prestazione del Servizio UGO (come sotto definito) da parte
                di questi ultimi.
              </strong>{' '}
              Pertanto, trattandosi di rapporti commerciali o, comunque, tra
              professionisti (B2B), non trova applicazione la normativa in
              materia di diritti del consumatore.
            </p>
            <ol>
              <li>
                <strong>Definizioni</strong>
              </li>
            </ol>
            <p>
              <strong>
                <em>Academy: </em>
              </strong>
              sezione della Piattaforma creata esclusivamente per gli operatori
              UGO e dedicata alla presentazione di contenuti in formato video,
              documenti e webinar in diretta, necessari per la corretta
              erogazione dei servizi e contenenti i termini della
              collaborazione. Premesso che gli UGO possiedono già le competenze
              necessarie per svolgere l'incarico affidatogli, l’Academy prevede
              la partecipazione a un corso di presentazione delle modalità di
              erogazione dei servizi e delle funzionalità della Piattaforma, che
              gli Ugo dovranno seguire in fase di attivazione dell’account Ugo.
              L’accesso alla Academy è subordinato all’approvazione della
              richiesta di iscrizione e all'esito della Selezione UGO come di
              seguito definita.
            </p>
            <p>
              <strong>
                <em>Beneficiari: </em>
              </strong>
              soggetti, persone fisiche, a beneficio dei quali è prestato
              (dall’Ugo) e/o richiesto (dal Caregiver) il Servizio Ugo.
            </p>
            <p>
              <strong>
                <em>Caregiver</em>
              </strong>
              : l’Utente che prenota il Servizio Ugo per conto dell’effettivo
              Beneficiario.
            </p>
            <p>
              <strong>
                <em>Compenso</em>
              </strong>
              : la somma riconosciuta all’Ugo che ha integralmente e
              correttamente prestato il Servizio Ugo nei confronti dei
              Beneficiari. L’ammontare del Compenso spettante a ciascun Ugo è
              indicato a preventivo nella scheda del servizio offerto sulla
              piattaforma e a consuntivo in base all’attività effettivamente
              svolta.{' '}
            </p>
            <p>
              <strong>
                <em>Condizioni di Utilizzo o CU</em>
              </strong>
              : le condizioni predisposte nel presente documento.
            </p>
            <p>
              <strong>
                <em>Contenuti</em>
              </strong>
              : (i) file contenenti testi, immagini, registrazioni audio e/o
              video, dati e/o informazioni organizzati in banca di dati e
              comunque tutti i file, documenti e/o informazioni in qualsiasi
              formato rese dagli Utenti e che UGO PNP rende disponibili
              attraverso la Piattaforma, o i Servizi, incluso qualsiasi
              Contenuto concesso in licenza da una terza parte; (ii) file
              contenenti testi, immagini, registrazioni audio e/o video, dati
              e/o informazioni organizzati in banca dati e comunque tutti i
              file, documenti e/o informazioni in qualsiasi formato che vengono
              trasmessi, copiati, inviati, messi a disposizione degli Utenti
              della Piattaforma anche dagli Utenti stessi.
            </p>
            <p>
              <strong>
                <em>Dati personali</em>
              </strong>
              : tutte le informazioni personali relative a ciascun Utente,
              inclusi il nome della persona, codice fiscale, l'indirizzo e
              numero di telefono, eventualmente raccolte tramite la Piattaforma.
            </p>
            <p>
              <strong>
                <em>Fee o Corrispettivo: </em>
              </strong>
              somma dovuta a UGO PNP dall’Ugo per la fruizione del Servizio
            </p>
            <p>
              <strong>
                <em>Piattaforma</em>
              </strong>
              : il dominio https://hellougo.com/, ed ogni suo contenuto, inclusi
              gli eventuali sottodomini, qualsiasi URL o loro porzioni
              <em>
                . La piattaforma di UGO è una web app, mobile first e
                responsive, concepita e strutturata per essere pienamente
                fruibile anche da smartphone. L’accesso alla piattaforma avviene
                tramite web. Al dominio{' '}
              </em>
              <a href="https://app.hellougo.com/login">
                <em>https://app.hellougo.com/login</em>
              </a>
              <em>
                {' '}
                l’operatore può accedere alla propria area personale, inserendo
                e-mail e password.
              </em>
              <strong>
                <em> </em>
              </strong>
              <em>
                La piattaforma permette a tutti gli operatori UGO di
                visualizzare i servizi disponibili, per accettare liberamente e
                senza alcun vincolo soltanto quelli che vogliono e possono
                erogare. Dopo aver accettato il servizio, l’operatore disporrà
                dei comandi in piattaforma per gestirlo in tutti i momenti
                pre-durante-post erogazione. Potrà inoltre mettersi in contatto
                con l’utente e/o con il team UGO per richiedere assistenza in
                caso di bisogno. Così come potrà annullare la propria
                disponibilità per il servizio, nel rispetto delle buone norme
                condivise e dell’utente. La piattaforma non dispone di alcun
                algoritmo per tracciare l’attività degli operatori e modulare la
                disponibilità o assegnazione dei servizi sulla base delle
                performance pregresse, né prevede logiche di penalizzazione per
                chi eroga meno servizi o in base a simili parametri. La
                piattaforma non dispone di localizzazione gps o altri sistemi
                che rilevino e traccino in tempo reale l’operatore e/o l’utente.
                La piattaforma inoltre lascia tutti gli operatori liberi di
                accettare o meno i servizi disponibili, organizzando in modo
                autonomo e libero la propria agenda: non è richiesto infatti, né
                è prevista questa funzionalità, indicare giorni e/o orari di
                attività né garantire la propria disponibilità a copertura di
                fasce orarie o aree geografiche.{' '}
              </em>
            </p>
            <p>
              <strong>
                <em>Prezzo: </em>
              </strong>
              somma corrisposta dai Beneficiari o dai Caregiver a fronte della
              fruizione del Servizio Ugo.
            </p>
            <p>
              <strong>
                <em>Progetti e/o forniture</em>
              </strong>
              : sono da intendersi partnership e attività sviluppate con
              soggetti terzi quali società, enti territoriali e realtà
              benefiche, al fine di aumentare l'aderenza terapeutica di
              eventuali pazienti in cura oppure di agevolare il diritto alla
              cura di persone anziane e fragili. Tali progetti presuppongono la
              gratuità del servizio UGO in ogni forma poiché sponsorizzati da
              enti terzi, che si fanno carico dell'affiancamento.
            </p>
            <p>
              <strong>
                <em>Selezione Ugo</em>
              </strong>
              : procedimento di selezione degli Ugo effettuato da Ugo PNP e che
              include, a mero titolo esemplificativo e non esaustivo: colloqui
              volti alla verifica dell’idoneità dei candidati UGO allo
              svolgimento delle attività di affiancamento di persone fragili o
              con ridotta mobilità; colloqui volti alla verifica della
              sussistenza dei requisiti e delle attitudini adatte per lo
              svolgimento dei Servizi Ugo.{' '}
            </p>
            <p>
              <strong>
                <em>Servizio</em>
              </strong>
              : Servizi che UGO PNP offre attraverso la Piattaforma consistente
              in uno spazio online a disposizione degli Utenti, come infra
              definiti, che permette di mettere in comunicazione i Beneficiari e
              i Caregiver con gli Ugo disponibili alla prestazione, nei
              confronti di persone fisiche o giuridiche, di servizi di
              affiancamento e/o accompagnamento di persone fragili o con ridotta
              mobilità al fine di aiutarle nello svolgimento di attività
              quotidiane. Il Servizio include gli strumenti messi a disposizione
              degli UGO per la gestione delle richieste del Servizio UGO.
            </p>
            <p>
              <strong>
                <em>Servizio UGO</em>
              </strong>
              : servizi di affiancamento e/o accompagnamento di persone fragili
              o con ridotta mobilità al fine di aiutarle nello svolgimento di
              attività quotidiane. Possono consistere, a mero titolo
              esemplificativo, nel fare una passeggiata, seguire insieme un
              ciclo di terapia, andare alle poste a pagare le bollette, recarsi
              nei negozi del quartiere per la piccola spesa quotidiana, andare
              al parco a leggere un libro, andare a una mostra oppure al cinema
              oppure in centro a fare due passi, ecc.
            </p>
            <p>
              <strong>
                <em>UGO</em>
              </strong>
              : tutte le persone fisiche già in possesso delle competenze
              tecniche necessarie per lo svolgimento del Servizio UGO,
              opportunamente selezionate dalla Società, che abbiano superato
              positivamente il corso di formazione, registrate alla Piattaforma
              e disponibili alla prestazione, nei confronti degli Utenti, di
              servizi di accompagnamento e affiancamento richiesto dall’Utente
              stesso.
            </p>
            <p>
              <strong>
                <em>Utente</em>
              </strong>
              : tutte le persone fisiche o giuridiche che accedono alla
              Piattaforma al fine di fruire del Servizio.
              <br />A seconda del contesto, il significato di un termine usato
              al singolare comprende il plurale, quello di un termine usato al
              maschile comprende il femminile, e viceversa.
            </p>
            <p>
              <strong>2. Descrizione della Piattaforma</strong>
            </p>
            <p>
              <strong>2.1 </strong>I Servizi vengono presentati per mezzo di una
              piattaforma online attraverso la quale:
            </p>
            <p>
              a) gli Utenti possono richiedere la prestazione di uno o più
              Servizi Ugo, resi dagli Ugo, che possono svolgersi a piedi, con
              l’utilizzo dei mezzi pubblici, con l’ausilio di un taxi oppure
              tramite veicolo proprio, ovvero messo a disposizione dall’Ugo; b)
              I Clienti (o i Caregiver) possono richiedere che il mezzo di
              trasporto, del Beneficiario oppure fornito dall’Ugo, sia
              comprensivo di attrezzatura speciale volta a facilitare lo
              spostamento della persona con particolari esigenze e/o necessità.
              Resta inteso che anche in tal caso UGO PNP non fornisce
              direttamente il mezzo di trasporto dotato di attrezzatura
              speciale, rivolgendosi a fornitori terzi estranei alla
              Piattaforma; c) l’UGO fornisce al Beneficiario o al Caregiver,
              qualora questi ne facciano richiesta, informazioni sullo
              svolgimento del Servizio di accompagnamento e il Caregiver, se
              presente, viene notificato della corretta conclusione del Servizio
              da parte dell’UGO; e) l’UGO e il Beneficiario (o il Caregiver), al
              termine di ogni Servizio, possono condividere la loro esperienza e
              livello di soddisfazione e/o gradimento del Servizio al fine di
              migliorare il Servizio per i futuri Utenti; f) l’Ugo viene
              supportato da UGO PNP nella presa in carico del Servizio UGO
            </p>
            <ol>
              <li>
                <strong>Privacy</strong>
              </li>
            </ol>
            <p>
              L'operatore UGO dichiara di avere preso visione dell’Informativa
              sulla Privacy di Ugo, disponibile all'indirizzo{' '}
              <a href="https://hellougo.notion.site/PRIVACY-97a0acfa909c46c08b070985262062d4">
                https://hellougo.notion.site/PRIVACY-97a0acfa909c46c08b070985262062d4
              </a>{' '}
              come modificata di volta in volta; tale Informativa disciplina
              l'acquisizione e l'utilizzo da parte di Ugo dei Dati personali
              degli operatori Ugo. In particolare, Ugo dà atto e conferma che
              ogni trattamento di dati personali che derivi dall’esecuzione
              delle presenti CU verrà effettuato unicamente per le finalità
              connesse a tale esecuzione (finalità contrattuali e/o di legittimo
              interesse) o per ulteriori finalità alle quali l’Utente abbia
              conferito espresso ed inequivocabile consenso, e comunque nel
              rispetto di quanto previsto dal D.Lgs. 196/2003 e ss.mm., D. Lgs
              101/2018 nonché dal Reg.to UE 679/2016 (altrimenti noto come
              “GDPR”).
              <br />
            </p>
            <p>
              In particolare, l’operatore UGO dichiara di essere stato informato
              circa:
            </p>
            <ul>
              <li>
                le finalità e le modalità del trattamento cui sono destinati i
                dati;
              </li>
              <li>
                la natura obbligatoria o facoltativa del conferimento dei dati;
              </li>
              <li>le conseguenze di un eventuale rifiuto di rispondere;</li>
              <li>
                l’esistenza eventuale di un trattamento di dati automatizzato;{' '}
              </li>
              <li>
                i soggetti o le categorie di soggetti ai quali i dati possono
                essere comunicati e l’ambito di diffusione dei dati medesimi;
              </li>
              <li>
                i diritti di cui agli artt. 7 (revoca del consenso), 15 (diritto
                di accesso), 16 (diritto di rettifica), 17 (diritto all’oblio),
                18 (diritto di limitazione di trattamento), 20 (diritto
                portabilità dati), 21 (diritto di opposizione)e 77 (diritto di
                proporre reclamo) del Reg.to UE 679/2016;
              </li>
              <li>
                il nome, la denominazione o la ragione sociale e il domicilio,
                la residenza o la sede del responsabile del trattamento.
              </li>
            </ul>
            <p>
              <br />
              L’operatore UGO, nell'ambito dell'adempimento dei propri obblighi
              contrattuali, trattando dati in nome e per conto della UGO PNP
              (titolare del trattamento), viene designato quale autorizzato al
              trattamento e si impegna a rispettare le istruzioni impartitegli
              in relazione ai dati personali e/o particolari degli Utenti
              (soggetti interessati), i quali potranno essere trattati e gestiti
              unicamente per le finalità contrattuali connesse all'esecuzione
              del rapporto instaurato con UGO PNP.
              <br />
            </p>
            <ol>
              <li>
                <strong>Account UGO</strong>
              </li>
            </ol>
            <p>
              4.1 Per iscriversi e usufruire del Servizio, e prima di poter
              diventare UGO e, quindi, prestare il Servizio UGO, ogni Utente
              deve preventivamente creare un solo Account Ugo personale, di cui
              sarà unico responsabile per tutte le attività svolte tramite esso,
              fornendo i dati personali che lo riguardano, indispensabili al
              corretto funzionamento del servizio di comunicazione tra persone.
              A tal fine, in caso di variazione dei dati indicati durante la
              fase di registrazione, l’Ugo è tenuto ad aggiornare il proprio
              account concordemente alle modifiche avvenute. La creazione
              dell’account e la registrazione dell’Utente si perfezionano con la
              conferma della richiesta da parte dell’Utente. Gli Utenti, con la
              creazione dell’Account Ugo, certificano di:
            </p>
            <ul>
              <li>
                avere compiuto <strong>almeno 18 anni</strong> al momento della
                loro iscrizione;
              </li>
              <li>
                <strong>non aver riportato condanne penal</strong>i e di non
                essere destinatario di provvedimenti che riguardano
                l’applicazione di misure di prevenzione, di decisioni civili e
                di provvedimenti amministrativi iscritti nel casellario
                giudiziale ai sensi della vigente normativa;
              </li>
              <li>
                non essere a conoscenza di essere sottoposto a{' '}
                <strong>procedimenti penali.</strong>
              </li>
            </ul>
            <p>
              <br />
              Nella compilazione dei form predisposti nella sezione riservata
              all’Ugo, l’Utente indicherà una User Id (di cui il sistema
              verificherà in automatico l’univocità) e la password preferita.
              Nel caso in cui la User-Id fosse già presente nel database,
              l’Utente dovrà indicarne una ulteriore fino a quando il sistema
              non verificherà positivamente l’univocità della stessa.{' '}
              <strong>
                La User-Id unitamente alla password, costituiranno le
                Credenziali di autenticazione dell’Ugo.
              </strong>
            </p>
            <p>
              La User-Id consentirà l’identificazione dell’UGO, quale soggetto a
              cui riferire l’attività o i servizi richiesti sulla Piattaforma
              nonché tutto quanto relativo ai Servizi UGO prestati. Le
              Credenziali di autenticazione sono personali, riservate e non
              cedibili. La creazione e attivazione dell’Account Ugo è confermata
              solo successivamente al positivo esito del procedimento di
              Selezione Ugo.{' '}
            </p>
            <p>
              Gli UGO, così come ogni Utente, si impegnano a non fornire alcuna
              informazione errata o falsa. In ogni caso, UGO PNP non potrà in
              alcun caso essere ritenuta responsabile dell’erroneità o della
              falsità, incolpevole, colpevole o dolosa, delle informazioni
              comunicate dagli Utenti.
            </p>
            <p>
              L’UGO si impegna ad utilizzare solo ed unicamente l’Account UGO
              inizialmente creato, indipendentemente dal fatto che sia stato
              registrato sotto la propria identità o sotto quella di terzi e
              indipendentemente dalla tipologia di Servizio richiesto. In nessun
              caso l’UGO potrà utilizzare l’Account UGO di terzi. Qualsiasi
              deroga a questa regola dovrà essere richiesta esplicitamente da
              parte dell’UGO a UGO PNP. L’UGO potrà quindi procedere solo a
              fronte di un'autorizzazione esplicita e specifica di UGO PNP. In
              caso di mancanza di tale esplicita autorizzazione, la creazione o
              l'utilizzo di nuovi account sotto la propria identità o sotto
              quella di terzi potrà comportare la Sospensione immediata e a
              tempo indeterminato degli Account UGO ovvero la loro definitiva
              cancellazione. Resta inteso che analoghi obblighi e divieti sono
              previsti per tutti gli Utenti della Piattaforma.
            </p>
            <p>
              Ciascun UGO ha la facoltà di procedere alla{' '}
              <strong>
                cancellazione del proprio Account personale inviandone richiesta
                tramite e-mail all’indirizzo hr@hellougo.com
              </strong>
              . La definitiva cancellazione dell’Account avverrà a seguito di
              conferma da parte di UGO PNP di avvenuta cancellazione.
            </p>
            <p>
              <strong>4.2 Cancellazione, Sospensione dell’Account</strong>
            </p>
            <p>
              L’UGO si obbliga a svolgere il servizio da lui accettato, con
              correttezza e diligenza, rispettando integralmente le CU, le Buone
              Norme e qualunque altro obbligo derivante dalla normativa vigente.
              In caso di accertata violazione da parte dell’Ugo di una o più
              delle CU o delle ulteriori previsioni, la UGO PNP si riserva, a
              sua discrezione e in considerazione della importanza della
              violazione, di interrompere la collaborazione e procedere alla
              Cancellazione o Sospensione (come infra definite) dell’Account del
              suddetto Ugo. Resta inteso che le violazioni potranno essere
              apprese anche mediante una o più segnalazioni inoltrate dagli
              altri Utenti o dagli Ugo con qualsiasi modalità).
            </p>
            <p>
              <strong>Cancellazione Account</strong>: La Società, in caso di
              inadempimenti ritenuti particolarmente gravi da parte dell’UGO, si
              riserva di interrompere la collaborazione con l’UGO. In tal caso
              l’Account del singolo Ugo viene completamente rimosso dalla
              Piattaforma e ogni dato riguardante l’Ugo viene cancellato.
              Pertanto, in seguito a cancellazione, UGO PNP non sarà più in
              possesso di alcun dato comunicato dall’Ugo in fase di
              registrazione e creazione dell’Account. A seconda della gravità
              della condotta, ricorrendone le condizioni, potranno essere
              effettuate delle trattenute nei pagamenti dovuti all’Ugo.
            </p>
            <p>
              <strong>Sospensione Account </strong>La Società, in caso di
              inadempimenti rilevanti si riserva di sospendere temporaneamente
              l’accesso alla piattaforma da parte dell’UGO. Ai fini della
              Sospensione dell’Account si considerano rilevanti, a titolo
              esemplificativo e non esaustivo, i seguenti comportamenti
              dell’UGO:{' '}
            </p>
            <ul>
              <li>
                doglianze e/o qualsiasi altra forma di protesta, riportate
                oralmente o per iscritto, prive di qualsivoglia fondamento e
                volte unicamente ad arrecare un pregiudizio a Ugo PNP;
              </li>
              <li>
                pretestuose richieste di pagamento e/o di rimborsi che non siano
                supportate da idonea prova documentale circa la fondatezza della
                pretesa;
              </li>
              <li>
                messaggi e/o comportamenti minacciosi, tali da turbare, anche
                solo potenzialmente, il personale, i collaboratori, utenti e/o i
                clienti di Ugo; messaggi e/o comportamenti volti a ingenerare in
                altri un’immagine distorta e negativa di Ugo, determinando un
                senso di avversione e risentimento nei confronti di Ugo stessa;
              </li>
              <li>
                condotte inadeguate e non conformi alla natura del Servizio che
                arrechino danno e/o pongano altri Accompagnatori e/o altri
                Utenti in situazioni di pericolo e/o di difficoltà
                nell’erogazione del Servizio;
              </li>
              <li>
                mancata comunicazione a UGO PNP dei dati relativi all’apertura
                della partita IVA che dovrà essere effettuata nei termini e
                secondo le modalità indicate all’articolo 6.2 di cui alle
                presenti CU;
              </li>
              <li>
                <strong>
                  svolgimento del Servizio con azioni e strumenti atti a creare
                  un rapporto diretto con gli Utenti escludendo la piattaforma
                  ed eliminando l’intermediazione di UGO PNP (c.d.
                  disintermediazione);
                </strong>
              </li>
              <li>
                svolgimento di attività lesive della sfera e della libertà di
                UGO PNP, avvalendosi - direttamente o indirettamente - di mezzi
                non conformi ai principi della correttezza professionale e
                idonei a danneggiare UGO PNP stessa (c.d. pratiche di
                concorrenza sleale);
              </li>
              <li>
                mancato o ritardato pagamento degli importi di cui al successivo
                Paragrafo 5.2.
              </li>
            </ul>
            <p>
              Nel caso in cui l’account venga sospeso, l’Ugo manterrà il proprio
              Account personale, e avrà la facoltà di accedere alla Piattaforma.
              Tuttavia, all’Ugo sarà impedito, temporaneamente, di utilizzare i
              Servizi offerti dalla Piattaforma e di accettare incarichi.
              Ricorrendo i presupposti potrà anche essere disposta la
              sospensione dei pagamenti nei confronti dell’Ugo.
            </p>
            <ol>
              <li>
                <strong>Selezione UGO e prenotazione del Servizio UGO</strong>
              </li>
            </ol>
            <p>
              5.1 Gli UGO sono selezionati da UGO PNP tramite una specifica
              procedura descritta all’indirizzo{' '}
              <a href="https://hellougo.com/lavora-con-ugo/">
                https://hellougo.com/lavora-con-ugo/
              </a>
              . Gli Ugo dichiarano che tutte le informazioni personali fornite
              in fase di selezione e registrazione sono corrette e veritiere e
              manlevano Ugo PNP da ogni responsabilità derivante dalla
              incompleta, incorretta e/o fraudolenta comunicazione di
              informazioni. In ogni caso, Ugo PNP non potrà in alcun caso essere
              ritenuta responsabile dell’erroneità o della falsità, incolpevole,
              colpevole o dolosa, delle informazioni comunicate dagli Ugo.
            </p>
            <p>
              L’UGO si obbliga ad osservare le indicazioni fornite dalla UGO PNP
              e necessarie per la corretta gestione del servizio, nonché a
              rispettare le buone pratiche di esecuzione riportate nel materiale
              informativo della Academy e di ogni allegato o documento o
              altrimenti comunicato da UGO PNP per iscritto o pubblicato sulla
              Piattaforma. Inoltre, in presenza di emergenze o casistiche
              particolari del servizio, ciascun UGO è tenuto a gestire il
              servizio secondo buon senso e ragionevolezza, con modalità non
              lesive della persona e atte a portare a termine l'incarico
              salvaguardando il benessere dell'Utente. Il percorso Academy
              fornisce ogni riferimento utile al fine di determinare le modalità
              corrette di gestione del servizio.
            </p>
            <p>
              In seguito alla conclusione di ogni Servizio Ugo, l’Utente ha la
              facoltà di pubblicare sulla Piattaforma una opinione personale
              sulla natura, qualità e svolgimento complessivo del Servizio Ugo.
              UGO PNP potrà tenere conto, a sua discrezione, dei contenuti di
              tali opinioni al fine di valutare la corretta esecuzione
              dell’incarico affidato all’Ugo e/o ricevere richieste e/o consigli
              da parte degli Utenti per migliorare il livello di qualità del
              Servizio e del Servizio Ugo.
            </p>
            <p>
              Resta inteso che, qualora le informazioni fornite dall’Ugo in fase
              di registrazione risultino erronee, incomplete, false e/o
              fraudolente, ovvero qualora l’Ugo tenga una condotta inadeguata,
              scorretta, non professionale o in ogni altro modo in contrasto con
              le regole e policy di UGO PNP rese note mediante la Piattaforma
              e/o la Academy nonché con gli standard qualitativi assistenziali
              di UGO PNP, quest’ultima ha la facoltà, in qualunque momento e
              senza alcun preavviso, di cessare il rapporto con l’Ugo,
              cancellare/sospendere/bannare l’Account Ugo e, qualora necessario,
              rimuovere ogni altro commento e/o feedback pubblicato dall’Ugo
              sulla Piattaforma. Negli stessi casi UGO PNP ha la facoltà di
              sospendere e/o trattenere i pagamenti nei confronti dell’Ugo al
              fine di valutare ed effettuare le opportune compensazioni con
              quanto dovute dall’Ugo in virtù di sanzioni, multe, danni,
              indennizzi o penali ad esso riferibili e/o applicabili.
              Ogniqualvolta l’Utente richieda il Servizio Ugo, la Piattaforma
              rende disponibili le informazioni relative a richieste,
              caratteristiche e servizi accessori come, a titolo esemplificativo
              e non esaustivo, a) necessità di un veicolo dotato di attrezzatura
              particolare per il trasporto di persone a mobilità ridotta messo a
              disposizione da un fornitore terzo. Resta inteso che Ugo non si
              occupa della esclusiva fornitura di mezzi di trasporto con
              attrezzatura speciale; b) data, ora, luogo e modalità di trasporto
              particolari in base alle necessità dell’Utente; c) se il Servizio
              Ugo è da erogarsi a piedi oppure con i mezzi pubblici oppure con
              la vettura dell’utente o con quella dell’operatore Ugo; d) se si
              prevede una singola tratta oppure andata e ritorno; e) se il
              Servizio Ugo viene prenotato per sé stessi oppure per un proprio
              caro in qualità di Caregiver; f) altre note di dettaglio che
              l’Utente voglia fornire utili all’erogazione di un Servizio Ugo o
              parte di esso. g) la preferenza per un “Ugo preferito” ovvero un
              operatore di fiducia che ha già svolto servizi e pienamente
              soddisfacenti per l’utente.{' '}
            </p>
            <p>
              <strong>
                Nel momento dell’accettazione dell’incarico, l’Ugo dichiara di
                conoscere ed accettare le specifiche richieste dell’Utente e,
                pertanto, di possedere ogni capacità e caratteristica richiesta
                e, pertanto, di essere in grado, senza riserva alcuna, di
                prestare il Servizio Ugo.
              </strong>
            </p>
            <p>
              È facoltà degli Utenti e degli UGO di stabilire di comune accordo
              le modalità di fruizione del Servizio Ugo, previa prenotazione da
              effettuarsi almeno ventiquattro (24) ore precedentemente alla
              fornitura del Servizio Ugo. I servizi last-minute sotto le 24 ore
              di preavviso possono essere richiesti e verranno gestiti dal
              servizio clienti: non si assicura però in merito a questi, esito
              positivo di organizzazione ed erogazione.
            </p>
            <p>
              <strong>5.2 Costi aggiuntivi. </strong>L’UGO si obbliga alla
              esecuzione del servizio accettato, nei tempi e con le modalità ivi
              definite.
              <strong> </strong>Ogni violazione da parte dell’UGO nel corso
              della esecuzione del servizio, il mancato servizio e/o ogni
              comportamento non corretto e non conforme ai tempi e modalità di
              esecuzione può comportare{' '}
              <strong>
                l’applicazione di somme aggiuntive e/o extra costi a carico
                degli UGO
              </strong>
              , come di seguito descritte.
            </p>
            <p>
              <br />
              In particolare, in caso di{' '}
              <strong>
                annullamento con meno di 24 ore di preavviso o di mancata
                erogazione del servizio,{' '}
              </strong>
              la società UGO pnp si riserva il diritto di addebitare i maggiori
              costi sostenuti a copertura della gestione amministrativa
              necessaria per garantire il servizio all’utente e/o gestire il
              disservizio e, in ogni caso, non inferiori ad euro 15,00.{' '}
            </p>
            <p>
              La Società può, in ogni caso, sospendere l'operatore, a sua
              discrezione e in considerazione della gravità della violazione e/o
              del disservizio. In ogni caso resta salvo il risarcimento del
              maggior danno a carico dell’UGO. <br />
              Nel caso in cui sia invece l’utente ad annullare un servizio:
              <br />
              <ul>
                <li>
                  se l'annullamento avviene fra le 24 e le 6 ore dall'inizio del
                  servizio, verrà riconosciuto all’operatore UGO il compenso a
                  titolo di rimborso, nella misura del 50% del compenso indicato
                  a preventivo e da lui accettato;{' '}
                </li>
                <li>
                  se l'annullamento avviene fra le 6 e le 0 ore dall'inizio del
                  servizio e/o se l'utente non è presente nel luogo e all'orario
                  di inizio servizio, verrà riconosciuto all’operatore UGO
                  incaricato il compenso a titolo di rimborso, nella misura del
                  100% del compenso indicato a preventivo e da lui accettato.
                </li>
              </ul>
            </p>
            <p>
              UGO non garantisce la solvibilità dei Clienti. In caso di
              insolvenza da parte di un cliente, la UGO PNP porrà in essere ogni
              ragionevole sforzo per supportare l'operatore nella gestione di
              problematiche nei rapporti con i Clienti e attiverà le procedure a
              sua disposizione per il recupero crediti, ai fini di assicurare a
              tutte le parti coinvolte, operatore Ugo compreso, la corretta
              copertura economica per il servizio reso.{' '}
              <strong>
                Resta inteso che, qualora il cliente, anche dopo i diversi
                solleciti, non proceda al saldo del servizio, UGO PNP non sarà
                in grado di compensare l’insolvenza.
              </strong>
            </p>
            <p>
              Durante l’erogazione dei servizi UGO, gli operatori sono coperti e
              tutelati da copertura assicurativa garantita da UGO e fornita da
              Zurich. Le coperture assicurativa previste sono le seguenti e
              prevedono una franchigia a carico dell’operatore:
            </p>
            <ul>
              <li>
                RC Danni a persone, copertura fino a 1ml€. Franchigia 1.000,00 €
              </li>
              <li>
                RC Danni a cose, copertura fino a 1ml€. Franchigia 200,00 €
              </li>
              <li>Kasko, copertura fino a 20k€. Franchigia 500,00 €.</li>
            </ul>
            <ol>
              <li>
                <strong>Inquadramento contrattuale</strong>
              </li>
            </ol>
            <p>
              <strong>6.1</strong>{' '}
              <strong>
                UGO quale prestatore d’opera a carattere occasionale
              </strong>
              .{' '}
            </p>
            <p>
              <a id="_heading=h.gjdgxs"></a>La prestazione dell’UGO verrà
              svolta, senza <strong>alcun vincolo di subordinazione</strong>,
              con <strong>autonomia organizzativa ed operativa</strong>, ed è da
              intendersi quale prestazione professionale a carattere occasionale
              ai sensi e per gli effetti dell’art. 2222 c.c..
            </p>
            <p>
              Aderendo e sottoscrivendo i presenti termini e condizioni di
              utilizzo l’UGO dichiara di possedere le qualità e le attitudini
              tecnico-professionali richieste dalla prestazione d’opera e
              manleva UGO PNP da ogni conseguenza e/o responsabilità in caso di
              infortunio occorso in occasione dell’esecuzione della prestazione
              prevista dal presente contratto.
            </p>
            <p>
              L’UGO svolgerà personalmente e autonomamente la prestazione e il
              servizio, senza assoggettamento ad alcun vincolo di subordinazione
              verso UGO PNP, senza assoggettamento ad alcun obbligo anche di
              natura disciplinare e senza l’osservanza di un orario di lavoro.
              <strong> </strong>UGO PNP non opererà alcuna geolocalizzazione
              dell’UGO né utilizzerà alcun algoritmo che possa influire sulle
              priorità nella prenotazione delle prestazioni, né utilizzerà alcun
              algoritmo che possa influire sulle priorità nella
              prenotazione/assegnazione delle prestazioni richieste dagli
              utenti. Il flusso è standard e paritetico per tutti gli operatori
              UGO che avranno pari opportunità di visualizzare, accettare,
              erogare servizi di accompagnamento. Resta inteso che UGO PNP non è
              responsabile di temporizzatori del server di posta.
            </p>
            <p>
              UGO PNP non imporrà alcun obbligo di rispettare regole vincolanti
              anche per quanto riguarda l’aspetto esteriore oppure il
              comportamento nei confronti del destinatario del servizio o
              l’esecuzione del lavoro; resta inteso che data la peculiarità
              dell’ambito in cui il servizio viene svolto, è richiesto agli
              operatori UGO di rispettare le buone norme di comportamento,
              dialogo, cura della propria persona e abbigliamento.{' '}
            </p>
            <p>
              UGO PNP non imporrà alcuna limitazione alla libertà del prestatore
              UGO di organizzare il proprio lavoro in autonomia o di svolgere
              prestazioni lavorative per terzi.<strong> </strong>
              L’UGO si fa carico di gestire in maniera autonoma i propri
              compensi, ricevute, fatture e di tenerne traccia, eventualmente
              affidandosi a professionisti di sua fiducia, senza gravare sulla
              UGO PNP.
            </p>
            <p>
              Gli UGO sono tenuti ad indicare se ricorre o meno l'obbligo
              contributivo <em>ex</em> L.335/95 e Decreto del Ministero del
              Lavoro n. 281/1996 e successive modificazioni e integrazioni,
              obbligo che ricorre in caso di superamento, nell’anno civile dal
              01 gennaio al 31 dicembre, del limite di 5.000,00 euro lordi.
              <strong>
                {' '}
                Il presente contratto sarà da considerare prestazione d’opera a
                carattere occasionale entro i limiti di euro 3.000,00 lordi
                annui, da intendersi al lordo di tutte le eventuali ritenute
                previste per legge. Superata detta soglia, qualora l’UGO intenda
                proseguire nello svolgimento delle attività nell’ambito, dovrà
                darne tempestiva comunicazione a UGO PNP
              </strong>{' '}
              e potrà proseguire a svolgere la propria opera unicamente
              nell’ambito dell’inquadramento contrattuale di cui al successivo
              articolo 6.2.
            </p>
            <p>
              <strong>
                6.2 UGO quale lavoratore autonomo titolare di partita IVA.{' '}
              </strong>
            </p>
            <p>
              Qualora l’operatore UGO in regime di prestazione occasionale
              superi il limite di euro 3.000,00 lordi, è tenuto a darne
              tempestiva comunicazione a UGO PNP comunicando l’eventuale numero
              di partita IVA entro e non oltre il termine di 30 (trenta) giorni
              dal superamento di detto limite. Si ricorda che potrà essere
              aperta partita IVA inserendo campo “data di inizio dell’attività”
              una data retroattiva fino a 30 giorni prima, data dalla quale sarà
              possibile emettere le relative fatture. A seconda dei casi, UGO
              PNP si riserva la facoltà di inviare all’UGO un messaggio di alert
              prima del raggiungimento della soglia dei 3.000,00 euro.{' '}
            </p>
            <p>
              Resta inteso che una volta raggiunta detta soglia, l’UGO potrà
              continuare a prestare i Servizi solo in regime di lavoratore
              autonomo opportunamente dotato di partita IVA. La verifica della
              sussistenza dei requisiti, delle condizioni e dei termini entro i
              quali sia necessario per l’Ugo provvedere all’emissione di
              documentazione contabile e/o fiscale, di ogni tipo e genere,
              rimane ad esclusiva cura e responsabilità dell’operatore Ugo per
              quanto di sua competenza.{' '}
            </p>
            <p>
              <strong>6.3</strong>{' '}
              <strong>
                La differenza con il servizio taxi e con il servizio NCC.{' '}
              </strong>
            </p>
            <p>
              Il Servizio UGO è una attività di affiancamento della persona,
              anche fragile, affinché questa possa svolgere le proprie attività
              quotidiane, non potrà in alcun modo essere assimilato al servizio
              taxi o al servizio di noleggio con conducente (c.d. “NCC”). UGO
              PNP non ha visibilità del tipo di servizio richiesto dall’Utente
              ed effettivamente prestato dall’UGO. Pertanto, qualora il Servizio
              dovesse ridursi a un mero accompagnamento assimilabile al servizio
              taxi o NCC, sarà onere dell’UGO segnalarlo e astenersi dal portare
              a termine tale tipo di servizio. Si ricorda, infatti, che svolgere
              il servizio di taxi o quello di noleggio con conducente senza il
              titolo autorizzativo equivale ad adibire i veicoli utilizzati a un
              uso diverso da quello consentito nella carta di circolazione. Lo
              svolgimento abusivo dell’attività, sanzionato dal Codice della
              strada, corrisponde, in concreto, all’accertato svolgimento di
              attività di trasporto di persone senza essere in possesso del
              prescritto titolo autorizzativo. In caso di violazione delle
              presenti condizioni, UGO PNP avrà la facoltà, in qualsiasi
              momento, a sua discrezione e senza darne preavviso, di
              sospendere/cancellare/bannare l’Account Ugo nonché di sospendere
              e/o trattenere i pagamenti nei confronti dell’Ugo al fine di
              valutare ed effettuare nel frattempo le opportune verifiche e/o
              segnalazioni.{' '}
            </p>
            <p>
              <strong>7.Pagamenti</strong>
            </p>
            <p>
              <strong>
                7.1 Servizi erogati a favore di clienti privati (B2C)
              </strong>
            </p>
            <p>
              UGO PNP mette a disposizione degli Utenti un servizio di terze
              parti, mediante il quale il Beneficiario o Caregiver che intenda
              usufruire del Servizio Ugo può procedere al pagamento della somma
              richiesta per lo svolgimento del Servizio Ugo (nel seguito,{' '}
              <strong>
                <em>Prezzo</em>
              </strong>
              ) mediante la Piattaforma (nel seguito,{' '}
              <strong>
                <em>Pagamento Online</em>
              </strong>
              ). Pertanto, UGO PNP non agisce né opera in qualità di venditore,
              né di fornitore di servizi di pagamento, limitandosi a fornire
              l’accesso alla Piattaforma ed al suo mantenimento. Il pagamento
              del servizio effettuato dal beneficiario del servizio viene poi
              suddiviso fra l’operatore che ha svolto l’affiancamento e UGO PNP
              attraverso una modalità automatica di split payment e come
              dettagliato nel seguente paragrafo.
            </p>
            <p>
              <strong>In caso di Pagamento effettuato online,</strong> i
              Beneficiari/Caregiver corrispondono il Prezzo direttamente
              mediante il servizio offerto da terze parti, tramite la
              Piattaforma: dovranno collegarsi, seguendo le istruzioni presenti
              sulla Piattaforma, al circuito sicuro di Stripe che garantisce la
              riservatezza dei dati e la sicurezza e l’efficienza delle
              transazioni. I Beneficiari/Caregiver inseriranno i dati relativi
              alla carta di credito direttamente sul server SSL, creato e
              garantito da Stripe e gestito direttamente dallo stesso,
              accetteranno le condizioni generali di contratto predisposte da
              Stripe, raggiungibili altresì al link “
              <a href="https://stripe.com/it/ssa">
                <em>https://stripe.com/it/ssa</em>
              </a>
              ” od ogni altro link come aggiornato di volta in volta, per poter
              accedere al servizio di pagamento. Stripe gestisce il pagamento
              con sistema “split payment”. Pertanto, l’Ugo riceverà,
              successivamente alla positiva conclusione del Servizio Ugo, solo
              il Compenso, mentre la Fee sarà automaticamente dedotta dal Prezzo
              pagato ed accreditata direttamente a UGO PNP, così come il costo
              della commissione Stripe applicata su ogni transazione di
              pagamento viene così suddivisa: 75% a carico dell’operatore e 25%
              a carico di UGO PNP. Stripe applica una percentuale diversa di
              commissione a seconda della tipologia di carta dell'utente. Di
              seguito è possibile visionare le casistiche sul sito di Stripe:{' '}
              <a href="https://stripe.com/it/pricing">
                https://stripe.com/it/pricing
              </a>
              . Resta inteso che UGO PNP non risponderà di eventuali danni
              derivanti all’Utente o all’Ugo dall’utilizzazione del servizio
              proposto da Stripe. L’operatore UGO potrà in ogni momento accedere
              al proprio profilo Stripe, usando le credenziali di accesso
              impostate al momento del primo accesso, e monitorare in questo
              spazio personale e dedicato, lo stato di ogni servizio e
              pagamento; in questo modo sarà facile e immediato per l’operatore
              controllare, a titolo esemplificativo e non esaustivo, i pagamenti
              ricevuti e quelli in transito, nonché eventuali notifiche legate
              ad aggiornamenti richiesti dell’anagrafica, campi mancanti,
              sostituzione o aggiornamento degli estremi per il pagamento,
              intervenendo immediatamente a risoluzione delle stesse o altro.
            </p>
            <p>
              <strong>
                In caso di Pagamento effettuato per mezzo bonifico bancario,
              </strong>{' '}
              i Beneficiari/Caregiver corrispondono il Prezzo direttamente
              mediante il servizio offerto da terze parti, tramite la
              Piattaforma e gestito da Stripe come dettagliatamente spiegato nel
              precedente paragrafo, nel caso del pagamento tramite carta.{' '}
            </p>
            <p>
              <strong>
                7.2 Servizi erogati a favore di aziende per garantirli ai loro
                utenti/clienti/associati (B2B)
              </strong>
            </p>
            <p>
              UGO PNP è sempre attenta alle necessità dei Beneficiari e, per
              questo, ricerca e valuta attivamente opportunità di partnership
              con soggetti terzi - es. aziende ospedaliere, case farmaceutiche,
              associazioni, Fondazioni, aziende, ecc. – (nel seguito, i Partner)
              stipulando con essi appositi accordi per l’applicazione di
              agevolazioni e facilitazioni nella fruizione del Servizio Ugo da
              parte dei Beneficiari.
            </p>
            <p>
              In caso di Progetti con aziende ed enti del terzo settore è
              possibile che il pagamento dei Servizi Ugo sia assolto dai Partner
              e che, pertanto, nulla sia dovuto da parte del Beneficiario e/o
              del Caregiver nei confronti dell’Ugo o di UGO PNP. In tali casi
              UGO PNP riceve direttamente dal Partner il pagamento del Servizio
              Ugo, secondo i termini e le modalità concordate con il Partner, e
              successivamente riconosce all’Ugo il corrispettivo determinato
              secondo le tariffe condivise nella sezione “Amministrazione”
              dell’Academy per il servizio da questi prestato, dedotta la Fee.
              Restano applicabili, in quanto compatibili, le disposizioni di cui
              ai precedenti paragrafi in merito alla rendicontazione ed alle
              compensazioni.
            </p>
            <p>
              <strong>7.3 Fatturazione.</strong>{' '}
            </p>
            <p>
              Nel contesto dei Progetti con le aziende e/o enti del terzo
              settore, e solo in essi, l’Ugo agisce quale fornitore di UGO PNP
              e, pertanto, sarà tenuto all’emissione della documentazione
              contabile e fiscale come da normativa applicabile e tempo per
              tempo vigente (es., a seconda dei casi, notula, ritenuta
              d’acconto, fattura, ecc.) nei confronti di UGO PNP. A seconda dei
              casi, i servizi resi dall’Ugo nei Progetti con le aziende e/o enti
              del terzo settore possono configurarsi come attività professionale
              (se in possesso di partita iva) o di prestazione occasionale (con
              applicazione di ritenuta d’acconto) qualora ne ricorrano i
              requisiti di legge.{' '}
            </p>
            <p>
              UGO PNP, all’inizio di ciascun mese (nel seguito,{' '}
              <strong>
                <em>Periodo di Rendicontazione</em>
              </strong>
              ) condivide un riepilogo dei Servizi Ugo espletati dal singolo Ugo
              per Progetti Speciali nel corso del mese precedente effettuando il
              calcolo del Compenso ed esplicitando eventuali compensazioni o
              trattenute.{' '}
              <strong>
                Dal giorno 25 all’ultimo giorno di ogni mese, solo dopo aver
                ricevuto conferma del riepilogo condiviso da parte
                dell’operatore, la UGO PNP dispone il pagamento del compenso a
                mezzo bonifico bancario ordinario e non istantaneo
              </strong>
              , relativo alla prestazione dei Servizi Ugo effettuati durante per
              Progetti Speciali il mese precedente. La Fee viene trattenuta da
              UGO PNP. Resta inteso che UGO PNP non è responsabile per eventuali
              ritardi nel trasferimento dell’importo all’Ugo per cause non
              dipendenti da UGO PNP. Si rammenta inoltre che tutte le conferme
              ricevute per il riepilogo dei pagamenti saranno considerate valide
              solo se inviate entro il 15 del mese, oltre tale data il pagamento
              verrà disposto nel mese successivo.
            </p>
            <ul>
              <li>
                <ol>
                  <li>
                    <strong>Preventivo e consuntivo</strong>
                  </li>
                </ol>
              </li>
            </ul>
            <p>
              Il Compenso relativo al servizio è sempre comunicato all’Utente e
              all’Ugo mediante un preventivo e un consuntivo:
            </p>
            <ul>
              <li>
                il preventivo riporta il Compenso calcolato al momento della
                prenotazione del Servizio in base alle richieste effettuate
                dall’Utente. Il preventivo è quindi meramente indicativo del
                Compenso effettivamente dovuto all’Ugo;
              </li>
              <li>
                il consuntivo riporta il Compenso effettivamente dovuto all’Ugo
                come aggiornato in base alle modifiche apportate. Il consuntivo
                è subordinato alle modalità di svolgimento del Servizio, tra cui
                la durata complessiva effettivamente impiegata dall’Ugo per
                l’erogazione del Servizio stesso.
              </li>
            </ul>
            <p>
              UGO PNP si riserva la facoltà di apportare modifiche, anche
              periodiche, all’ammontare delle tariffe senza alcun obbligo di
              notifica agli Utenti e agli Ugo. Pertanto, è onere dell’Utente e
              dell’Ugo accertarsi delle corrette tariffe al momento
              rispettivamente della richiesta e della presa in carico del
              Servizio.
            </p>
            <p>
              <strong>7.5 Fatturazione</strong>
            </p>
            <p>
              UGO PNP non è responsabile della fatturazione delle somme pagate
              dal Beneficiario/Caregiver all’Ugo quale Compenso. L’invio della
              documentazione contabile e/o fiscale, laddove richiesta, sarà
              eseguito direttamente dall’Ugo di volta in volta a ciò tenuto ai
              sensi della normativa applicabile. UGO PNP non è in alcun modo
              tenuto alla verifica della sussistenza dei requisiti, delle
              condizioni e dei termini entro i quali sia necessario provvedere
              all’emissione di documentazione contabile e/o fiscale, di ogni
              tipo e genere, nei confronti degli Utenti.
            </p>
            <p>
              UGO PNP si impegna esclusivamente ad emettere tutta la
              documentazione contabile nei confronti degli Ugo con riguardo
              all’importo della Fee. Pertanto, qualora il Beneficiario/Caregiver
              proceda al Pagamento Online, UGO PNP emetterà direttamente fattura
              nei confronti dell’Ugo per l’importo relativo alla Fee.{' '}
            </p>
            <ul>
              <li>
                <ol>
                  <li>
                    <strong>Compensazioni</strong>
                  </li>
                </ol>
              </li>
            </ul>
            <p>
              UGO PNP ha la facoltà di sospendere e/o trattenere temporaneamente
              il Compenso dell’Ugo per il tempo necessario ad accertare ed
              effettuare le opportune compensazioni con quanto dovuto dall’Ugo a
              UGO PNP in virtù di sanzioni, multe, danni, indennizzi o extra
              costi o costi aggiuntivi ad esso riferibili e/o applicabili in
              tutti i casi di:
            </p>
            <ul>
              <li>mancato rispetto delle CU e dei Termini e Condizioni;</li>
              <li>
                mancato rispetto della legge e dei regolamenti applicabili in
                relazione alla prestazione dei Servizi Ugo;
              </li>
              <li>
                ogniqualvolta risulti che l’Ugo, per qualsiasi motivo e a
                qualsiasi titolo, sia debitore nei confronti di UGO PNP o dei
                suoi Utenti. In tal caso la sospensione e/o la trattenuta deve
                essere limitata alla parte di Compenso che possa garantire la
                soddisfazione di detto debito.
              </li>
            </ul>
            <p>
              Qualora UGO PNP ritenga di effettuare una Compensazione, ne dà
              notizia all’Ugo nel riepilogo dei Servizi Ugo effettuati nel mese
              di riferimento. Resta inteso che l’Ugo ha diritto di contestare
              tali sospensioni e/o trattenute entro 48 ore dall’invio del
              suddetto riepilogo. Trascorso tale termine le Compensazioni
              effettuate si danno per accettate e riconosciute e l’Ugo decade da
              qualsiasi diritto di contestazione in merito ad esse.
            </p>
            <p>
              <strong>8.Imposte e Tasse</strong>
            </p>
            <p>
              <strong>
                L’Ugo è l’unico responsabile (i) della determinazione dei propri
                obblighi relativi a licenze, autorizzazioni, imposte, contributi
                ed ogni altro onere di natura fiscale, contributiva e/o
                amministrativa
              </strong>
              , e dei relativi adempimenti (ii) della segnalazione, raccolta,
              versamento delle imposte, contributo ed ogni altro onere di natura
              fiscale, contributiva e/o amministrativa, nonché (iii) della
              valutazione circa l’opportunità o l’obbligo di inclusione nel
              Corrispettivo di qualsiasi imposta sul valore aggiunto (IVA)
              applicabile o qualsiasi altra imposta indiretta comunque prevista
              per altre tipologie di visitatori, o imposte sul reddito
              (“Imposte”).
            </p>
            <p>
              Le normative fiscali potrebbero imporre a UGO PNP di raccogliere
              informazioni fiscali dell’Ugo e/o di trattenere le imposte dai
              pagamenti agli Ugo.{' '}
              <strong>
                In particolare, UGO PNP tratterrà la ritenuta d’acconto
                addebitandola all’Ugo come anticipo sulle imposte che lo stesso
                deve pagare e provvederà al versamento all’erario in qualità di
                sostituto d'imposta.
              </strong>
              Se un Ugo non fornisce la documentazione richiesta ai sensi della
              legge applicabile (ad es. un codice fiscale o una partita iva) per
              adempiere agli obblighi fiscali, contributivi o di ogni altra
              natura (es. di ritenuta delle imposte dai pagamenti dovuti
              dall’Ugo), UGO PNP si riserva il diritto di trattenere i pagamenti
              fino all’importo rispettivo dell’imposta/onere come previsto dalla
              legge fino alla risoluzione della questione.
            </p>
            <p>
              Resta inteso che, salvo il caso in cui sia tassativamente imposto
              dalla legge di volta in volta applicabile, UGO PNP non paga tasse
              per conto degli Utenti o degli Ugo. Gli Utenti e gli Ugo sono gli
              unici responsabili di eventuali Imposte ad essi relative e
              derivanti da qualsiasi attività intercorsa mediante la
              Piattaforma.
            </p>
            <p>
              A seconda delle circostanze, tra cui il numero di transazioni
              condotte e l'oggetto della transazione, la legge può richiedere
              licenze, certificazioni o particolari adempimenti fiscali e
              amministrativi. UGO PNP non è responsabile di determinare le
              casistiche in cui è necessario essere in possesso di una licenza
              e/o di un certificato per l'uso della Piattaforma, per
              l’organizzazione, gestione e conduzione dei Servizi Ugo ovvero per
              lo svolgimento di una transazione attraverso la Piattaforma.
            </p>
            <p>
              <strong>9. Obblighi dell’Ugo</strong>
            </p>
            <p>L’Ugo accetta, dichiara e si impegna a:</p>
            <ul>
              <li>
                utilizzare la Piattaforma e l’Account Ugo in maniera
                esclusivamente personale e non cedere a terzi, a nessun titolo e
                per nessuna ragione, l’Account Ugo;{' '}
              </li>
              <li>
                non impiegare la Piattaforma, il Servizio e l’account personale
                al fine di arrecare disagi, disturbo o inconvenienti a terzi,
                ovvero compiere atti illeciti e/o fraudolenti come, a titolo
                esemplificativo e non esaustivo: arrecare danni alla
                Piattaforma, inviare messaggi di spam o altre comunicazioni
                indesiderate, diffondere materiale contenente virus, worm,
                trojan o altri codici, file, script, agenti o programmi dannosi
                per il gli altri utenti o terzi;
              </li>
              <li>
                non intervenire e/o effettuare modifiche al software della
                Piattaforma, decompilare, disassemblare, modificare e/o creare
                lavori derivati dalla Piattaforma o una parte di essa;{' '}
              </li>
              <li>
                non copiare, conservare, modificare, preparare e/o distribuire
                lavori derivati o alterare in qualunque modo i contenuti forniti
                dalla Piattaforma;{' '}
              </li>
              <li>
                non utilizzare alcun tipo di robot, spider, piattaforma di
                ricerca/reperimento di siti, o qualunque altro dispositivo,
                processo o mezzo automatico per accedere, recuperare, effettuare
                scraping o indicizzare qualunque porzione della Piattaforma e/o
                dei suoi contenuti;
              </li>
              <li>
                mantenere una condotta adeguata e conforme alla natura del
                Servizio Ugo senza arrecare danno e/o porre in situazione di
                pericolo l’Utente stesso e/o UGO PNP;
              </li>
              <li>
                non fare uso, trasportare con mezzo proprio o dell’Utente,
                consegnare e/o svolgere ogni altra attività legata all’impiego,
                uso, commercio e/o distribuzione di sostanze stupefacenti;
              </li>
              <li>
                utilizzare i Dati Personali degli Utenti esclusivamente nel
                quadro del presente contratto e per le finalità ivi previste.
              </li>
            </ul>
            <p>
              UGO PNP si riserva il diritto di effettuare controlli al fine di
              verificare che l’Utente non ponga in essere alcuna delle condotte
              indicate al presente articolo o contraria alla normativa in
              vigore. Qualora UGO PNP riscontri che la condotta di un utente sia
              in violazione con il presente articolo, UGO PNP ha la facoltà, in
              qualsiasi momento a sua discrezione e senza darne preavviso, di
              sospendere/cancellare/bannare l’account Ugo nonché di sospendere
              e/o trattenere i pagamenti nei confronti dell’Ugo al fine di
              valutare ed effettuare le opportune compensazioni con quanto
              dovute dall’Ugo in virtù di sanzioni, multe, danni, indennizzi o
              penali ad esso riferibili e/o applicabili.
            </p>
            <p>
              <strong>Obblighi dell’Ugo che utilizza veicolo proprio</strong>
            </p>
            <p>
              L’Ugo che utilizzi veicolo proprio durante lo svolgimento del
              Servizio Ugo accetta, dichiara e si impegna a quanto segue:
            </p>
            <ul>
              <li>
                fornire i dati del proprio veicolo affinché la compagnia
                assicurativa dell’Ugo sia responsabile in solido con la
                compagnia assicurativa di UGO PNP in caso di danno a cose e/o
                terzi arrecati dall’Ugo durante lo svolgimento del Servizio Ugo;
              </li>
              <li>
                garantire che l’autoveicolo che utilizzerà è sua proprietà e/o
                nella propria disponibilità per averne ricevuto il possesso e/o
                facoltà di utilizzo, senza limitazione alcuna, dal legittimo
                proprietario e che la circolazione del veicolo non è in
                contrasto con la volontà di quest’ultimo;
              </li>
              <li>
                dichiarare che l’autoveicolo è dotato di apposita polizza
                assicurativa RCA valida, nonché di tutti i dispositivi e
                requisiti previsti dalla normativa tempo per tempo vigente, e
                pertanto manleva UGO PNP e l’Utente, per tutta la durata del
                Servizio Ugo, da ogni responsabilità derivante dal mancato
                adempimento di tali obblighi;
              </li>
              <li>
                dichiarare che l’autoveicolo è in buono stato di manutenzione e
                funzionamento e la circolazione dello stesso non costituisce né
                può costituire pericolo per l’incolumità propria e/o degli
                eventuali altri passeggeri, degli Utenti né di terzi;
              </li>
              <li>
                dichiarare che l’autoveicolo è stato revisionato, è in regola
                con le disposizioni di legge concernenti la revisione periodica
                delle automobili e non è oggetto di provvedimenti limitativi
                della sua circolazione, quale, a mero titolo di esempio e senza
                pretesa di esaustività, fermo amministrativo e simili.
              </li>
            </ul>
            <p>
              La responsabilità per ogni eventuale sanzione amministrativa di
              carattere pecuniario che dovesse essere comminata in relazione
              alle modalità di impiego del veicolo in violazione al codice della
              strada è normalmente imputata all’Ugo, salvo il caso in cui sia
              dimostrato che la responsabilità sia da attribuire all’Utente.
            </p>
            <p>
              Qualora Ugo non rispetti gli obblighi elencati nel presente
              articolo quest’ultimo manleva UGO PNP e l’Utente da ogni
              responsabilità per danni arrecati a cose e/o terzi derivanti
              dall’impiego del veicolo.
            </p>
            <p>
              <strong>10. Responsabilità di UGO PNP</strong>
            </p>
            <p>
              10.1 <strong>Esclusione di garanzie</strong>
            </p>
            <p>
              L’Ugo prende atto e accetta che l'utilizzo della Piattaforma e del
              Servizio da essa offerto viene svolto interamente sotto la propria
              responsabilità e con rischio a proprio carico. La Piattaforma e il
              Servizio da essa erogato sono forniti "come sono" e "come
              disponibili", senza garanzie e/o responsabilità di alcun tipo.
              Tutte le garanzie esplicite ed implicite, incluse, senza
              limitazioni, le garanzie di commerciabilità e idoneità per uno
              scopo particolare sono espressamente escluse nella misura massima
              consentita dalla legge, e nella stessa misura, UGO PNP declina
              ogni garanzia per la sicurezza, l'affidabilità, la tempestività,
              la precisione e le prestazioni della Piattaforma.
            </p>
            <p>
              Nella misura massima consentita dalla legge, UGO PNP declina ogni
              garanzia per altri servizi o beni eventualmente ricevuti tramite o
              pubblicizzati sulla Piattaforma.
            </p>
            <p>
              Nella misura massima consentita dalla legge, UGO PNP declina ogni
              responsabilità in merito alla eventuale trasmissione di virus
              informatici o altri componenti dannosi in relazione al Piattaforma
              o al Servizio.
            </p>
            <p>
              10.2 <strong>Limitazioni di responsabilità</strong>
            </p>
            <p>
              In nessun caso UGO PNP potrà essere ritenuto responsabile per
              danni a terzi o alle parti contraenti risultanti, direttamente o
              indirettamente, dall'uso della Piattaforma o del Servizio, o
              risultanti da qualsiasi contratto intervenuto tra gli Ugo,
              l’Utente e/o terzi. Tale esclusione di responsabilità riguarda,
              tra gli altri, danni diretti, indiretti, accidentali, speciali,
              esemplari e consequenziali, inclusi: perdita di profitti, perdita
              di dati o perdita di avviamento; danni informatici; costo di
              prodotti o servizi sostitutivi; qualunque danno connesso a lesioni
              personali o fisiche.
            </p>
            <p>
              Tale limitazione di responsabilità si intende estesa anche ai casi
              in cui UGO PNP sia informata della possibilità di tali danni.
            </p>
            <p>
              I danni cui la limitazione di responsabilità si riferisce possono
              derivare: dall'uso o abuso della Piattaforma o del Servizio, dalla
              impossibilità di utilizzare la Piattaforma o il Servizio o da
              interruzione, sospensione, modifica, alterazione, o cessazione
              della Piattaforma o del Servizio.
            </p>
            <p>
              Tale limitazione si applica anche in relazione ai danni subiti a
              causa di altri servizi o prodotti ricevuti attraverso pubblicità o
              in connessione con la Piattaforma o il Servizio o qualsiasi link
              sulla Piattaforma, nonché a causa di informazioni o consigli
              ricevuti tramite o pubblicizzati in connessione con la Piattaforma
              o il Servizio o qualsiasi link sulla Piattaforma. Tali limitazioni
              si applicano nella misura massima consentita dalla legge.
            </p>
            <p>
              10.3 <strong>Sicurezza e feedback degli Utenti</strong>
            </p>
            <p>
              UGO PNP ha a cuore la sicurezza dei propri Utenti ed il buon esito
              dei propri Servizi nonché delle transazioni poste in essere
              mediante la Piattaforma e pone in essere ogni sforzo finalizzato
              al miglior grado di trasparenza possibile. Tuttavia, UGO PNP non è
              tenuta a verificare l'esatta identità dei propri Utenti e Ugo né
              può confermare l'identità di ciascun Utente e Ugo.
            </p>
            <p>
              Allo stesso modo, UGO PNP non è tenuta alla verifica, né
              preventiva né posteriore, dei commenti o feedback (nel seguito, i{' '}
              <strong>
                <em>Feedback</em>
              </strong>
              ) rilasciati dagli Utenti e/o dagli Ugo relativamente ad un
              Servizio Ugo. Qualora un Utente o un Ugo ritengano che i Feedback
              possano essere offensivi per sé, sono pregati di darne immediata
              comunicazione a UGO PNP scrivendo a{' '}
              <a href="mailto:customercare@hellougo.com">
                customercare@hellougo.com.
              </a>{' '}
              UGO PNP verificherà il contenuto del Feedback segnalato,
              ponderando l’opportunità di adottare eventuali provvedimenti che
              potranno includere, a seconda dei casi e ad esclusiva discrezione
              di UGO PNP, la sospensione e/o la cancellazione dell’Account
              dell’Utente e/o Ugo autore del Feedback segnalato.
              <br />
              Allo stesso modo, qualora UGO PNP rilevi uno o più feedback che
              segnalano problemi e comportamenti scorretti da parte di un
              operatore, si riserva la possibilità di approfondire e/o procedere
              alla sospensione dell’account, a tutela dei propri utenti e della
              qualità del proprio servizio.{' '}
            </p>
            <p>
              <strong>
                11. Contenuti pubblicati su o tramite la Piattaforma
              </strong>
            </p>
            <p>
              11.1 <strong>Contenuti Provenienti dagli Ugo</strong>
            </p>
            <p>
              Tutti i Contenuti pubblicati sulla Piattaforma, trasmessi
              attraverso, o accessibili tramite link dalla Piattaforma, sono di
              esclusiva responsabilità dell'Ugo da cui tali Contenuti
              provengono. L'Ugo è il solo responsabile per qualsiasi Contenuto
              pubblicato, e-mail o materiale altrimenti reso disponibile tramite
              la Piattaforma. L'Ugo è consapevole che UGO PNP non controlla e
              non è responsabile per i Contenuti messi a disposizione attraverso
              la Piattaforma, e che utilizzando la Piattaforma, l'Ugo può essere
              esposto a Contenuti offensivi, indecenti, imprecisi, illegali,
              fuorvianti.
            </p>
            <p>
              11.2 <strong>Link ad altri siti web</strong>
            </p>
            <p>
              La Piattaforma, e i Servizi potrebbero contenere e/o rimandare a
              link di siti web o risorse di terze parti. UGO PNP non è
              responsabile per: (i) la disponibilità o la sicurezza di tali siti
              web o risorse; o (ii) i contenuti, i prodotti, o i servizi su o
              disponibili da tali siti web o risorse. I link a tali siti web o
              risorse non implicano alcun sostegno e/o adesione da parte di UGO
              PNP a detti siti web o risorse o ai Contenuti, prodotti o servizi
              disponibili da tali siti web o risorse. L'Ugo si assume
              l'esclusiva responsabilità e tutti i rischi che derivano dall’ uso
              di tali siti web o risorse o dai Contenuti, prodotti o Servizi su
              o disponibili da tali siti web o risorse.
            </p>
            <p>
              UGO PNP non è inoltre responsabile per le politiche di
              riservatezza o pratiche adottate su altri siti web. Quando un Ugo
              clicca su un collegamento che indirizza ad un altro sito o app,
              l’Ugo è responsabile per la lettura e/o l’adesione alle politiche
              di riservatezza di detto sito o app.
            </p>
            <p>
              11.3 <strong>Autorizzazione di Ugo</strong>
            </p>
            <p>
              Fermo quanto sopra, gli Utenti e gli Ugo autorizzano UGO PNP ad
              utilizzare ogni immagine fotografica e/o ripresa video che li
              ritragga durante lo svolgimento del Servizio Ugo, salva la facoltà
              di revocare tale autorizzazione mediante comunicazione scritta.
              Gli Ugo autorizzano altresì irrevocabilmente UGO PNP all’utilizzo
              di tutti i Contenuti ed ogni loro parte o elemento, senza
              restrizione alcuna, in tutto il mondo, in perpetuo e comunque per
              tutta la durata della protezione legale quale sancita in ogni
              paese del mondo.
            </p>
            <p>
              A titolo esemplificativo ma non esaustivo, nella suddetta
              autorizzazione s'intende anche ricompreso il diritto di:
            </p>
            <ul>
              <li>
                pubblicare, diffondere, comunicare al pubblico e mettere a
                disposizione del pubblico in modo che ciascuno possa avervi
                accesso nel momento e nel luogo scelto individualmente, in tutto
                od anche solo in parte, con ogni mezzo e/o tecnologia e con
                qualsiasi modalità (anche interattiva), attualmente noti e/o
                sviluppati in futuro, i Contenuti attraverso la Piattaforma;
              </li>
              <li>
                ai fini di quanto sopra, riprodurre e modificare i Contenuti in
                ogni modo o forma per rispettare i vincoli tecnologici imposti
                dalle modalità di funzionamento della Piattaforma;
              </li>
              <li>
                autorizzare gli altri Utenti della Piattaforma a riprodurre i
                Contenuti al fine di visualizzarli sui loro terminali
                utilizzando i Servizi e la Piattaforma.
              </li>
            </ul>
            <p>
              I diritti di cui sopra si intendono concessi in via non esclusiva
              e pertanto l'Ugo resta legittimato ad utilizzare gli stessi
              Contenuti in ogni forma e modo.{' '}
            </p>
            <p>
              11.4 <strong>Dichiarazione dell'Ugo</strong>
            </p>
            <p>
              L’Ugo dichiara di disporre pienamente e legittimamente di tutti i
              diritti relativi ai Contenuti (a titolo esemplificativo ma non
              esaustivo: diritti d'autore, diritti di immagine e/o della
              personalità in genere, diritti di marchio ed altri diritti di
              privativa, diritti di tutela della riservatezza, diritti relativi
              al ritratto ecc.) che pubblica sulla Piattaforma.
            </p>
            <p>
              L'Ugo dichiara espressamente che laddove egli non sia pienamente
              titolare dei diritti sui Contenuti che pubblica, è stato
              espressamente autorizzato a disporre dei Contenuti dai terzi
              titolari di tali diritti ed è dunque pienamente legittimato a
              rilasciare l'autorizzazione ad utilizzare i Contenuti con
              l'ampiezza prevista nelle presenti Condizioni.
            </p>
            <p>
              L'Ugo si impegna a non pubblicare qualsiasi Contenuto se tale
              Contenuto e/o il suo utilizzo:
            </p>
            <p>
              è falso, osceno, pornografico, diffamatorio, od include
              informazioni razziste, violente, offensive, moleste, od istiga a
              commettere reati o è comunque illegale in Italia o nel luogo in
              cui l'Ugo usa i Servizi o la Piattaforma od in base ad altre leggi
              comunque applicabili;
            </p>
            <p>
              viola le norme in materia di trattamento dei dati personali o in
              materia di tutela del segreto industriale e delle informazioni
              riservate; presuppone il possesso in capo all'Ugo di
              autorizzazioni, diritti o requisiti richiesti dalla legge di cui
              questi non dispone; viola brevetti, marchi, diritti d'autore od
              altro diritto di terzi; contiene virus informatici o qualsiasi
              programma o software progettato per interrompere, distruggere,
              danneggiare od anche solo limitare le funzionalità di qualsiasi
              software, hardware od apparato di rete o dei Servizi o della
              Piattaforma stessi;
            </p>
            <p>
              sia in qualunque modo dannoso per gli altri Ugo, per gli Utenti o
              per i terzi.
            </p>
            <p>
              <strong>12. Modifiche</strong>
            </p>
            <p>
              UGO PNP si riserva il diritto, a sua sola discrezione, di
              modificare la Piattaforma o i Servizi, ovvero di modificare le CU,
              in qualsiasi momento e con preavviso non inferiore a 15 giorni. In
              caso di modifiche alle CU, esse saranno pubblicate sulla
              Piattaforma e sarà aggiornata la “Data ultimo aggiornamento” in
              calce alle CU. Continuando ad accedere o ad utilizzare la
              Piattaforma o i Servizi dal momento dell’avvenuto preavviso di
              modifica, l’Ugo accetta tali condizioni così come modificate.
              Qualora ritenesse non accettabili tali modifiche, cesserà di
              accedere e utilizzare la Piattaforma.
            </p>
            <p>
              <strong>
                13. Diritti di proprietà intellettuale ed industriale
              </strong>
            </p>
            <p>
              Fatte salve le disposizioni contenute sulla Informativa per la
              Privacy di UGO PNP e nel precedente Articolo 10, qualsiasi
              Contenuto trasmesso mediante la Piattaforma dagli Ugo, sia per
              posta elettronica o con altri mezzi, per qualsiasi motivo, sarà
              trattato come non confidenziale e non di esclusiva proprietà. Nel
              caso in cui gli utenti detengano tutti i diritti di tali
              comunicazioni o Contenuti, essi concedono esplicitamente a UGO PNP
              ed ai suoi concessionari designati una licenza non esclusiva, con
              il diritto perpetuo, e in tutto il mondo, di copiare, distribuire,
              visualizzare, eseguire, pubblicare, tradurre, adattare,
              modificare, e o utilizzare tale materiale per qualsiasi scopo,
              indipendentemente dalla forma o mezzo (ora noto o attualmente non
              noto) in cui viene utilizzato.
            </p>
            <p>
              Si prega di non pubblicare sulla Piattaforma informazioni
              riservate o tutelate come diritti di proprietà intellettuale o
              industriale, a meno che non sia stato concordato diversamente per
              iscritto.
            </p>
            <p>
              Se un utente della Piattaforma ritiene che i suoi diritti di
              proprietà intellettuale o industriale o altri siano stati violati
              da una pubblicazione sulla Piattaforma da parte di un altro
              utente, il primo, entro 48 ore dalla scoperta della violazione,
              deve immediatamente inviare una comunicazione scritta a UGO PNP
              all’indirizzo e-mail:{' '}
              <a href="mailto:customercare@hellougo.com">
                customercare@hellougo.com.
              </a>
            </p>
            <p>Per essere efficace, la notifica deve includere:</p>
            <ul>
              <li>
                La firma fisica o elettronica della persona autorizzata ad agire
                per conto del titolare di un diritto esclusivo che è stato
                presumibilmente violato;
              </li>
              <li>
                L'identificazione del Contenuto protetto da copyright che si
                ritiene sia stato violato;
              </li>
              <li>
                Informazioni ragionevolmente sufficienti per permetterci di
                contattare la parte reclamante, tra cui indirizzo, numero di
                telefono e, se disponibile, indirizzo di posta elettronica al
                quale la parte ricorrente può essere contattata;
              </li>
              <li>
                L'identificazione del materiale che si ritiene essere oggetto di
                attività illecita e le informazioni ragionevolmente sufficienti
                per permetterci di individuare il materiale;
              </li>
              <li>
                Una dichiarazione in cui la parte lesa ritiene in buona fede che
                l'uso del materiale non è autorizzato dal titolare del
                copyright, dall'agente incaricato o dalla legge;
              </li>
              <li>
                Una dichiarazione in cui la parte lesa dichiara che le
                informazioni contenute nella notifica sono accurate e, che la
                parte reclamante è autorizzata ad agire per conto del titolare
                di un diritto esclusivo, presumibilmente violato.
              </li>
            </ul>
            <p>
              L'Ugo prende atto e accetta che al momento del ricevimento di un
              avviso di reclamo per la violazione della proprietà intellettuale,
              UGO PNP potrà rimuovere immediatamente i materiali identificati
              dalla Piattaforma con esclusione di ogni responsabilità nei suoi
              confronti né verso gli Utenti o terzi.
            </p>
            <p>
              <strong>14. Manleva</strong>
            </p>
            <p>
              L'Ugo si impegna a liberare, difendere, manlevare e tenere indenne
              UGO PNP e le sue affiliate e consociate, e i loro funzionari,
              direttori, dipendenti e agenti da e contro qualsiasi pretesa,
              responsabilità, danni, perdite e spese, inclusi, senza
              limitazione, ragionevoli onorari legali e contabili, derivanti da,
              o in qualsiasi modo correlati a:
            </p>
            <ul>
              <li>
                l'accesso o l'utilizzo della Piattaforma, dei Servizi o dei
                Contenuti da parte sua o la violazione delle presenti Condizioni
                di Utilizzo da parte sua;
              </li>
              <li>
                i Contenuti elaborati, trasmessi, copiati, inviati, messi a
                disposizione dall’Ugo stesso;
              </li>
              <li>
                delle seguenti azioni svolte dall’Ugo stesso: l'interazione con
                qualunque Utente; la richiesta di un Servizio.
              </li>
            </ul>
            <p>
              In ogni caso, la UGO PNP si riserva il diritto, a sua esclusiva
              discrezione, di predisporre la propria difesa con un consulente
              legale di propria scelta, contro tutte le rivendicazioni, cause
              legali o di altra natura che comportano conseguenze finanziarie
              poste in essere da parte dell'Ugo, senza che l'Ugo possa eccepire
              alcunché. In tal caso, le spese legali saranno sostenute e/o
              addebitate all’UGO, salvo diverso accordo.
            </p>
            <p>
              <strong>15. Gestione reclami</strong>
            </p>
            <p>
              Se un Utente e/o operatore UGO ritiene che la condotta tenuta da
              altri Utenti e/o operatori UGO sia inappropriata, che i suoi
              diritti di proprietà intellettuale o industriale siano stati lesi,
              così come la sua privacy, ovvero vi siano malfunzionamenti di
              natura tecnica della Piattaforma, l'Utente stesso, entro 48 ore
              dalla scoperta della violazione, deve immediatamente inviare una
              comunicazione scritta a UGO PNP S.r.l., e-mail:{' '}
              <a href="mailto:customercare@hellougo.com">hr@hellougo.com.</a>
            </p>
            <p>Per essere efficace, la notifica deve includere:</p>
            <ul>
              <li>
                La firma fisica o elettronica della persona autorizzata ad agire
                per conto del titolare di un diritto esclusivo che è stato
                presumibilmente violato;
              </li>
              <li>
                i dati dell’Utente che sono stati forniti al momento della
                creazione dell’Account Utente al fine di poterlo correttamente
                individuare;
              </li>
              <li>
                una spiegazione dettagliata del malfunzionamento ovvero della
                violazione e delle ragioni per cui si ritiene che tale
                violazione sia inappropriata e/o lesiva dei diritti dell’Utente
                che sta presentando il reclamo;
              </li>
              <li>
                i dati personali dell’Utente collegati all’Account Utente di cui
                si ritiene che abbia posto in essere la violazione e/o la
                condotta illegittima;
              </li>
              <li>
                una dichiarazione in cui la parte lesa dichiara che le
                informazioni contenute nella notifica sono accurate e, che la
                parte reclamante è autorizzata ad agire per conto del titolare
                di un diritto esclusivo, presumibilmente violato. Con
                particolare riguardo ai reclami riguardanti questioni di
                violazione della proprietà intellettuale o industriale, l’Utente
                sarà anche tenuto a comunicare nel reclamo:
              </li>
              <li>
                L'identificazione del Contenuto protetto da copyright o
                disciplina sui marchi registrati che si ritiene sia stato
                violato;
              </li>
              <li>
                L'identificazione del materiale che si ritiene essere oggetto di
                attività illecita e le informazioni ragionevolmente sufficienti
                per permettere di individuare il materiale;
              </li>
              <li>
                Una dichiarazione in cui la parte lesa ritiene in buona fede che
                l'uso del materiale non è autorizzato dal titolare del copyright
                o del marchio registrato, dall'agente incaricato o dalla legge;
              </li>
            </ul>
            <p>
              L'Utente prende atto ed accetta che al momento del ricevimento di
              un avviso di reclamo di violazione della proprietà intellettuale,
              UGO PNP può rimuovere immediatamente i materiali identificati
              sulla Piattaforma senza l’assunzione di alcuna responsabilità nei
              confronti degli Utenti o di terzi.
            </p>
            <p>
              <strong>16. Relazione tra le parti</strong>
            </p>
            <p>
              UGO PNP, i Clienti e gli UGO sono parti indipendenti tra loro,
              ognuno dei quali agisce in suo nome e per i propri scopi. Le
              presenti CU non creano alcun vincolo di subordinazione, di
              agenzia, rappresentanza, associazione, intermediazione,
              partnership, rapporto di lavoro o di franchising tra UGO PNP e
              qualsiasi Utente, eccezion fatta per quanto concerne i Progetti
              Speciali nei quali potrà instaurarsi un rapporto diretto con gli
              UGO di volta in volta configurato quale rapporto di lavoro
              autonomo occasionale o in regime di partita IVA, come
              rispettivamente indicato dagli articoli 6.1. e 6.2 delle presenti
              CU.
            </p>
            <p>
              Nessuna delle clausole contenute nelle CU, ovvero nelle policy di
              Ugo, ovvero di qualsivoglia altro materiale può essere considerato
              inteso a costituire, creare, dare effetto o comunque riconoscere
              una società, un'agenzia, una joint venture ovvero una entità di
              business formale di qualsiasi tipo, ovvero a creare un rapporto
              fiduciario tra UGO PNP, i Clienti e gli UGO. I diritti e gli
              obblighi delle parti devono essere limitati a quelli espressamente
              indicati nel presente documento.
            </p>
            <p>
              Il Servizio offerto da UGO PNP consiste esclusivamente nel mettere
              in contatto gli Utenti interessati a fruire del Servizio Ugo e gli
              Ugo disponibili a prestare tali servizi. UGO PNP non è e non
              agisce come un venditore, né come compratore, rappresentante o
              mediatore per conto di alcuna delle parti coinvolte. UGO PNP
              gestisce la Piattaforma attraverso la quale gli Utenti possono
              effettuare le transazioni ed i pagamenti, ma non ha alcun ruolo
              nella transazione tra gli Utenti.
            </p>
            <p>
              Resta ferma la facoltà di UGO PNP di annullare le richieste e/o
              offerte di Servizio Ugo, ancorché già confermate, che ritenga
              possano contravvenire alle regole di cui alle presenti CU o altri
              regolamenti comunque pubblicati sulla Piattaforma e, allo stesso
              modo, di sospendere gli Account mediante i quali tali violazioni
              siano state poste in essere.
            </p>
            <p>
              <strong>17. Legge applicabile e foro competente</strong>
            </p>
            <p>
              Le presenti Condizioni di Utilizzo sono regolate e interpretate in
              conformità con le leggi della Repubblica Italiana.
            </p>
            <p>
              Tutte le controversie per quanto riguarda la validità,
              interpretazione o esecuzione delle presenti Condizioni Generali
              d'Uso saranno rimesse alla competenza esclusiva del Tribunale di
              Milano.
            </p>
            <p>
              <strong>18. Condizioni aggiuntive</strong>
            </p>
            <p>
              La Società non è responsabile per i rapporti creatisi tra gli
              Utenti.
            </p>
            <p>
              Le rubriche delle clausole delle Condizioni d'uso hanno funzione
              descrittiva e non ne influenzano l’interpretazione;
            </p>
            <p>
              L'Utente accetta che i diritti e gli obblighi contenuti nelle
              presenti Condizioni di Utilizzo, nonché gli eventuali altri
              documenti che sono incorporati in esse per mezzo di riferimento
              possono essere liberamente e legittimamente trasferiti da Ugo a
              terze parti nel caso di fusione o acquisizione, o di altro evento;
            </p>
            <p>
              In ogni caso, tramite la Piattaforma, gli Utenti si impegnano a
              rispettare tutte le leggi nazionali e internazionali e tutti i
              regolamenti attualmente in vigore per l'utilizzo dei Servizi di
              UGO PNP in relazione alle attività svolte sul Piattaforma dagli
              Utenti stessi. Nessuna deroga alle presenti Condizioni di Utilizzo
              sarà concessa, senza l'autorizzazione scritta ottenuta ed un nuovo
              contratto firmato tra UGO PNP e l'Utente;
            </p>
            <p>
              Tutte le comunicazioni all'attenzione degli Utenti saranno inviate
              all'indirizzo e-mail fornito al momento della registrazione sulla
              Piattaforma. I messaggi e le notifiche sono considerate ricevute
              24 ore dopo l'invio dell'e-mail, a meno che non sia stata ricevuta
              una notifica che indichi che l'indirizzo e-mail non sia valido o
              non funzionante.
            </p>
            <p>Milano, 23/02/2024</p>
            <p>
              Firma:{' '}
              <em>
                l’accettazione esplicita avviene attraverso il modulo di
                raccolta consensi condiviso con tutti gli operatori{' '}
              </em>
            </p>
            <p>
              <a id="_Hlk158197987"></a>Firma:{' '}
              <em>
                l’accettazione esplicita avviene attraverso il modulo di
                raccolta consensi condiviso con tutti gli operatori{' '}
              </em>
            </p>
            <p>* * *</p>
            <p>
              Ai sensi dell'art. 1341 e 1342 e ss. del codice civile, l’UGO
              dichiara di aver letto e compreso i Termini e le condizioni
              generali di contratto per servizi sopra descritti ed espressamente
              e specificatamente sottoscrive ed accetta i seguenti articoli:
              Art. 4.2 (Cancellazione e sospensione dell’account); Art. 5.2
              (costi aggiuntivi); Art.7.6 (Compensazioni); Art. 9 (Obblighi
              dell’Ugo); Art. 10 (Responsabilità dell’Ugo); Art. 10.1
              (Esclusione di garanzie); Art. 10.2 (Limitazioni di
              responsabilità); Art. 11.1 (Contenuti); Art. 11.2 (Link e siti
              web); Art. 11.3 ( Autorizzazione); Art. 14 (Manleva); Art. 17
              (legislazione applicabile e foro competente).{' '}
            </p>
            <p>Milano, 23/02/2024</p>
            <p>
              Firma:{' '}
              <em>
                l’accettazione esplicita avviene attraverso il modulo di
                raccolta consensi condiviso con tutti gli operatori{' '}
              </em>
            </p>
          </StyledGeneratedHtmlContainer>
        </Container>
      </section>
    </div>
  );
};

export default TermsAndConditions;
